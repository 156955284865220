import Image from "../Image";
import LinkComponent from "../LinkComponent";

import * as styles from "./styles.module.scss";

interface Props {
  icon?: StoryblokGatsbyImageAsset;
  title?: string;
  cta?: LinkElement[];
  className?: string;
}

export function IconCard({ icon, title, cta, className }: Props) {
  return (
    <div className={`${styles.iconCard} ${className}`}>
      <div className={styles.image}>{icon && <Image data={icon} fluid />}</div>

      <h3 className={styles.title}>{title}</h3>

      {cta?.[0] && (
        <LinkComponent {...cta[0]} className={styles.cta}>
          {cta[0].title}
        </LinkComponent>
      )}
    </div>
  );
}
