import ReactPaginate from "react-paginate";
import arrowLeft from "@/assets/images/icons/arrow-left.png";
import arrowRight from "@/assets/images/icons/arrow-right.png";
import * as styles from "./styles.module.scss";

/**  This component is used to conduct client based pagination where data in algolia is not normalized*/

type ClientPagination = {
  handlePageClick: (event: { selected: number }) => void;
  pageCount: number;
};

const ClientPagination = ({ handlePageClick, pageCount }: ClientPagination) => {
  return (
    <div>
      <ReactPaginate
        nextLabel={<img src={arrowRight} alt="next page" />}
        previousLabel={<img src={arrowLeft} alt="previous page" />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageCount}
        pageCount={pageCount}
        containerClassName={styles.paginationContainer}
        disabledClassName={styles.paginationDisabled}
        activeClassName={styles.paginationActive}
      />
    </div>
  );
};

export default ClientPagination;
