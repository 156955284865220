// extracted by mini-css-extract-plugin
export var card = "styles-module--card--63240";
export var cardsContainer = "styles-module--cardsContainer--627d3";
export var cardsGrid = "styles-module--cardsGrid--48c2f";
export var ctaCard = "styles-module--ctaCard--c988f";
export var ctaCardDesktop = "styles-module--ctaCardDesktop--523ef";
export var ctaCardMobile = "styles-module--ctaCardMobile--4759e";
export var horizontalCard = "styles-module--horizontalCard--82458";
export var label = "styles-module--label--de956";
export var pagination = "styles-module--pagination--70c9d";
export var wrapper = "styles-module--wrapper--f6104";