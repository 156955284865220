import Button from "@/components/elements/Button";
import Heading from "@/components/elements/Heading";
import LinkComponent from "@/components/elements/LinkComponent";
import almond from "@/assets/svgs/seeds/almond.svg";
import leafMobile from "@/assets/svgs/news/leafMobile.svg";
import leafDesktop from "@/assets/svgs/leafAuthor.svg";

import * as styles from "./styles.module.scss";

export function NewsCta({ title, description, link }: NewsCtaType) {
  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.box}>
          <span>Newsletter</span>
          <Heading level={3}>{title}</Heading>
          <p>{description}</p>
          {Array.isArray(link) &&
            link.map((item) => (
              <Button variant="primary" key={item.url}>
                <LinkComponent
                  url={item.url}
                  with_icon={item.with_icon}
                  type={item.type}
                >
                  {item.title}
                </LinkComponent>
              </Button>
            ))}
          <img src={almond} alt="almond" className={styles.almond} />
          <picture className={styles.leaf}>
            <source srcSet={leafDesktop} media="(min-width: 992px)" />
            <img src={leafMobile} alt="leaf" />
          </picture>
        </div>
      </div>
    </section>
  );
}
