// extracted by mini-css-extract-plugin
export var cardContainer = "styles-module--card-container--e330c";
export var description = "styles-module--description--e260c";
export var eventTags = "styles-module--event-tags--1d2d2";
export var eventsImage = "styles-module--events-image--74847";
export var flower = "styles-module--flower--06e5a";
export var headingContent = "styles-module--heading-content--5eb40";
export var imageWrapper = "styles-module--image-wrapper--88733";
export var section = "styles-module--section--70035";
export var shape = "styles-module--shape--90761";
export var tagWrapper = "styles-module--tag-wrapper--f06f0";
export var upcomingEvent = "styles-module--upcoming-event--a3d48";
export var withEvent = "styles-module--with-event--694e2";