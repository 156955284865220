import { RemarkRichTextComponents } from "@/components/elements/RichText/RichText";
import Image from "@/components/elements/Image";
import Heading from "@/components/elements/Heading";
import Icon from "@/components/elements/Icon";
import LinkComponent from "@/components/elements/LinkComponent";
import * as styles from "./styles.module.scss";

export function ImageAndDescription({
  title,
  content,
  image,
  email_label,
  email,
}: ImageAndDescriptionType) {
  return (
    <section className={styles.imageAndDescription}>
      <div className={styles.imageWrapper}>
        {image && <Image fluid data={image} alt="" />}
      </div>

      <div className={styles.content}>
        <Heading level={2} className={styles.title}>
          {title}
        </Heading>

        {content && (
          <div className={styles.description}>
            <RemarkRichTextComponents content={content} />
          </div>
        )}

        <div className={styles.contactUsContainer}>
          {email_label && (
            <div className={styles.emailLabelContainer}>
              <Icon name="blueLeafIcon" className={styles.leafIcon} />

              <h3 className={styles.emailLabel}>{email_label}</h3>
            </div>
          )}

          {email && (
            <LinkComponent
              url={email?.[0]?.url ?? "/"}
              type={email?.[0]?.type ?? "internal"}
              className={styles.email}
            >
              {email?.[0]?.title}
            </LinkComponent>
          )}
        </div>
      </div>
    </section>
  );
}
