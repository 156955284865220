import clsx from "clsx";
import RichText from "@/components/elements/RichText";

import * as styles from "./styles.module.scss";

export function TextContent({ content, image }: TextContentType) {
  return (
    <section className={styles.wrapper}>
      <div className={clsx(styles.sectionContainer, "container")}>
        <div className={styles.content}>
          <RichText content={content}></RichText>
        </div>

        {image?.image ? (
          <div className={styles.imageWrapper}>
            <img src={image.image} alt={image.alt_tag ?? ""} />
          </div>
        ) : null}
      </div>
    </section>
  );
}
