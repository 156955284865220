interface Options {
  itemsPerPage: number;
}

export function getPaginatedResults(
  items: any[],
  { itemsPerPage = 20 }: Options
) {
  const pages: { [key: number]: any[] } = {};
  const totalPages = Math.ceil(items?.length / itemsPerPage);

  let _items = items;
  let currentPage = 0;

  while (_items.length > 0) {
    pages[currentPage] = _items.slice(0, itemsPerPage);

    _items = _items.slice(itemsPerPage);

    currentPage++;
  }

  return { totalPages, pages };
}
