import { Link } from "gatsby";
import dayjs from "dayjs";

import clsx from "clsx";
import Tag from "@/components/elements/Tag";
import ClipPath from "@/components/elements/ClipPath";
import Heading from "@/components/elements/Heading";
import ImagePlaceholder from "@/components/elements/ImagePlaceholder";
import Image from "@/components/elements/Image";

import truncate from "@/base/helpers/truncateString";
import { useLanguageContext } from "@/base/context/LanguageContext";
import LinkComponent from "../LinkComponent";
import Icon from "../Icon";
import * as styles from "./styles.module.scss";
import { InlineVideo } from "./inline-player";

function HeaderWrapper({
  url,
  children,
}: {
  url: string;
  children: React.ReactNode;
}) {
  if (!url) {
    return <div>{children}</div>;
  }

  return (
    <Link to={url} className={styles.link}>
      {children}
    </Link>
  );
}

export function VerticalCard({
  heroImage,
  publishedDate,
  title,
  excerpt,
  categories,
  url = "/",
  labels,
  className,
  badge,
  getNameByValue,
  ctaLabel = "Read more",
  videoUrl,
  displayDate = false,
  displayTags = false,
  variant = "",
  ctaType,
}: CardDataType) {
  const { t } = useLanguageContext();

  return (
    <div className={clsx(styles.cardWrapper, className)} data-variant={variant}>
      <HeaderWrapper url={url || videoUrl}>
        <div className={styles.imageWrapper}>
          <>
            {/* <div className={styles.labels}>
              {Array.isArray(labels) &&
                labels.map((label) => (
                  <Tag
                    type={label}
                    key={label}
                    size="small"
                    className={styles.verticalCardTag}
                  />
                ))}
            </div> */}
            {heroImage?.image ? (
              <Image data={heroImage} fluid className={styles.heroImage} />
            ) : typeof heroImage === "string" ? (
              <Image data={heroImage} alt="" className={styles.heroImage} />
            ) : heroImage?.filename ? (
              <img
                src={heroImage?.filename}
                alt=""
                className={styles.heroImage}
              />
            ) : (
              <ImagePlaceholder />
            )}
          </>
        </div>

        {displayDate && publishedDate && (
          <span className={styles.date}>
            {dayjs(publishedDate).format("DD MMMM YYYY")}
          </span>
        )}

        {badge && <span className={styles.badge}>{badge}</span>}

        <div className={styles.content}>
          <Heading level={3} className={styles.title} data-variant={variant}>
            {title}
          </Heading>

          {excerpt && (
            <p className={styles.excerpt} data-variant={variant}>{`${truncate(
              excerpt,
              28
            )}`}</p>
          )}
        </div>

        {displayTags && categories && categories.length > 0 && (
          <div className={styles.categories}>
            {categories?.map((category) => (
              <Link to={`/updates/${category}`} key={category}>
                <Tag
                  text={getNameByValue && getNameByValue(category)}
                  size="small"
                />
              </Link>
            ))}
          </div>
        )}

        <p className={styles.readMore}>
          {/* TODO: Check if custom read more labels are necessary */}
          {t`read-more`} <Icon name="arrowUpRight" />
        </p>
      </HeaderWrapper>
    </div>
  );
}
