import dayjs from "dayjs";
// eslint-disable-next-line import/order
import clsx from "clsx";
import Heading from "@/components/elements/Heading";

import Button from "@/components/elements/Button";
import ClipPath from "@/components/elements/ClipPath";
import Image from "@/components/elements/Image";
import LinkComponent from "@/components/elements/LinkComponent";

import * as styles from "./styles.module.scss";

export const ResearchGrantsHero = ({
  box_description,
  box_title,
  hero_image,
  title,
  description,
  open_grant,
}: ResearchGrantsType) => {
  const openGrant = open_grant?.content
    ? {
        slug: open_grant?.full_slug,
        ...open_grant?.content,
      }
    : undefined;
  const hasOpenGrant = !!openGrant;

  return (
    <section
      className={clsx(
        {
          [styles.withGrant]: hasOpenGrant,
        },
        styles.section
      )}
    >
      <div className="container">
        <div className={styles.headingContent}>
          <Heading level={1}>{title}</Heading>
          <p>{description}</p>
        </div>
      </div>
      <div className="container">
        <div>
          {hero_image && (
            <div className={styles.imageWrapper}>
              <ClipPath version={2}>
                <Image data={hero_image} fluid strategy="eager" />
              </ClipPath>
            </div>
          )}
          {openGrant && (
            <div className={styles.futureWrapper}>
              <span>
                🎯 Deadline: {dayjs(openGrant.gr_date).format("DD MMMM YYYY")}
              </span>
              <Heading level={3}>{openGrant.gr_title}</Heading>
              <p>{openGrant.gr_description}</p>

              <div>
                <Button variant="outlined">
                  <LinkComponent
                    url={`/${openGrant.slug}`}
                    type="internal"
                    with_icon
                  >
                    Apply for a research grant
                  </LinkComponent>
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.sideHeroBox}>
        <Heading level={3}>{box_title}</Heading>
        <p>{box_description}</p>
      </div>
    </section>
  );
};
