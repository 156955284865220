import { graphql, useStaticQuery } from "gatsby";
import { useState } from "react";

import useDataSource from "@/base/hooks/useDataSource";
import Heading from "@/components/elements/Heading";
import HorizontalCard from "@/components/elements/HorizontalCard";

import * as styles from "./styles.module.scss";

export function PastEvents({ title }: PastEventsType) {
  const { getNameByValue: getCategoryName } =
    useDataSource<CategoryType>("categories");

  const { events } = useStaticQuery<{
    events: {
      nodes: {
        full_slug: string;
        content: string;
      }[];
    };
  }>(graphql`
    query {
      events: allStoryblokEntry(
        filter: {
          field_component: { eq: "template_event" }
          # field_event_is_finished_boolean: { eq: true }
        } # sort: { fields: field_event_date_string, order: DESC }
      ) {
        nodes {
          full_slug
          content
        }
      }
    }
  `);

  const parsedEvents = events.nodes.map((event) => {
    const {
      event_thumbnail,
      event_date,
      event_title,
      event_description,
      event_categories,
      event_labels,
      event_register_url,
    } = JSON.parse(event.content);
    return {
      heroImage: event_thumbnail,
      publishedDate: event_date,
      title: event_title,
      excerpt: event_description,
      categories: event_categories,
      url: `/${event.full_slug}`,
      labels: event_labels,
      registerUrl: event_register_url,
      getNameByValue: getCategoryName,
    };
  });
  return (
    <section className={styles.section}>
      <div className="container">
        <Heading level={2}>{title}</Heading>
        {parsedEvents.length > 0 && (
          <div className={styles.pastEventsContainer}>
            {parsedEvents.map((event) => (
              <HorizontalCard
                key={event.url}
                className="horizontal-card"
                {...event}
              />
            ))}
          </div>
        )}
        {/* TODO: Fix pagination after launch, currently is not working as expected */}
        {/* <Pagination
          currentPage={page}
          setPage={setPage}
          total={Math.floor(parsedEvents.length / 5)}
          className="pagination"
        /> */}
      </div>
    </section>
  );
}
