import { useState } from "react";

import { graphql, useStaticQuery } from "gatsby";
import useDataSource from "@/base/hooks/useDataSource";
import Heading from "@/components/elements/Heading";
import VerticalCard from "@/components/elements/VerticalCard";
import HeaderScientificUpdate from "@/components/sections/ScientificUpdateList/HeaderScientificUpdate";

import Pagination from "@/components/elements/Pagination";

import HorizontalCard from "@/components/elements/HorizontalCard";

import { useLanguageContext } from "@/base/context/LanguageContext";
import * as styles from "./styles.module.scss";

export function ScientificUpdateList({
  heading,
  subheading,
}: ScientificUpdateListType) {
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const { getNameByValue } = useDataSource<CategoryType>("categories");

  const { language_code } = useLanguageContext();

  const data = useStaticQuery(graphql`
    query UpdatesQuery {
      updates: allStoryblokEntry(
        filter: { field_component: { eq: "template_scientificUpdate" } }
        sort: { fields: field_su_date_of_publication_string, order: DESC }
      ) {
        nodes {
          full_slug
          content
        }
      }
    }
  `);

  const updatesByLanguage = data?.updates?.nodes?.filter(
    (node) => node.full_slug?.split("/")?.[0] === language_code
  );

  const updatesAll = updatesByLanguage?.map((node) => ({
    ...JSON.parse(node.content),
    slug: node.full_slug,
  }));

  const stories = updatesAll;

  return (
    <section className={styles.wrapper}>
      {/* TODO: this component will possibly get hidden */}
      <HeaderScientificUpdate
        heading={heading}
        subheading={subheading}
        setPage={setPage}
        dataSourceCategory="categories"
      />

      <div className="container">
        <div className={styles.headingContent}>
          <Heading level={1}>{heading}</Heading>
          <p>{subheading}</p>
        </div>

        {page === 0 && stories?.[0] && (
          <div className={styles.mainCard}>
            <HorizontalCard
              key={stories[0]?._uid}
              title={stories[0]?.su_title}
              publishedDate={stories[0]?.su_date_of_publication}
              heroImage={stories[0]?.su_hero_img}
              excerpt={stories[0]?.su_excerpt}
              labels={stories[0]?.su_labels}
              categories={stories[0]?.su_categories}
              url={`/${stories[0]?.slug}`}
              getNameByValue={getNameByValue}
              isNewsletterCardDisplayed={true}
            />
          </div>
        )}

        <div className={styles.updatesList}>
          {stories?.slice(1)?.map((scientificUpdate) => (
            <VerticalCard
              key={scientificUpdate._uid}
              title={scientificUpdate.su_title}
              publishedDate={scientificUpdate.su_date_of_publication}
              heroImage={scientificUpdate.su_hero_img}
              excerpt={scientificUpdate.su_excerpt}
              labels={scientificUpdate.su_labels}
              categories={scientificUpdate.su_categories}
              url={`/${scientificUpdate.slug}`}
              getNameByValue={getNameByValue}
              displayDate={true}
              displayTags={true}
            />
          ))}
        </div>

        <Pagination
          setPage={setPage}
          currentPage={page}
          totalPages={totalPages}
          containerId="su-box"
        />
      </div>

      {/* <img src={greyBg} alt="Grey background" className={styles.greyBg} /> */}
    </section>
  );
}
