import { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";

import Button from "@/components/elements/Button";
import Heading from "@/components/elements/Heading";
import LinkComponent from "@/components/elements/LinkComponent";
import useDataSource from "@/base/hooks/useDataSource";
import List from "@/components/elements/VerticalCardList";
import leaf from "@/assets/images/event-leaf.png";
import TopClip from "@/assets/svgs/cards-top-clip.svg";

import { useLanguageContext } from "@/base/context/LanguageContext";
import * as styles from "./styles.module.scss";

export function RecentEvents({ heading, btn_label, cards }: RecentEventsType) {
  const data = useStaticQuery<{
    recentScientificUpdates: {
      edges: {
        node: StoryblokStory;
      }[];
    };
  }>(graphql`
    query RecentEventsQuery {
      recentScientificUpdates: allStoryblokEntry(
        filter: { field_component: { eq: "template_scientificUpdate" } }
        sort: { fields: field_su_date_of_publication_string, order: DESC }
      ) {
        edges {
          node {
            uuid
            content
            full_slug
          }
        }
      }
    }
  `);

  const { language_code } = useLanguageContext();

  const languageFilteredData = useMemo(() => {
    return data?.recentScientificUpdates?.edges?.filter(({ node }) =>
      node.full_slug?.startsWith(language_code || "uk")
    );
  }, [data]);

  const initialData = useMemo(() => {
    const scientificUpdates = languageFilteredData
      ? languageFilteredData.map(({ node }) => ({
          ...(JSON.parse(node.content) as UpdatePageTemplate),
          full_slug: node.full_slug,
          uuid: node.uuid,
        }))
      : [];
    return { scientificUpdates };
  }, [data]);

  const { getNameByValue } = useDataSource<CategoryType>("categories");

  const updatesList = initialData.scientificUpdates?.map((item) => ({
    uuid: item.uuid,
    key: item._uid,
    title: item.su_title,
    publishedDate: item.su_date_of_publication,
    excerpt: item.su_excerpt,
    url: `/${item.full_slug}`,
    heroImage: item.su_hero_img,
    labels: item.su_labels,
    categories: item.su_categories,
    getNameByValue,
  }));

  const latestThreeUpdates = updatesList.slice(0, 3);
  const customSelectedUpdates = updatesList.filter((event) =>
    cards?.includes(event?.uuid)
  );

  const updatesToDisplay = [
    ...customSelectedUpdates,
    ...latestThreeUpdates,
  ].slice(0, 3);

  return (
    <section className={styles.wrapper}>
      <div className={`container ${styles.container}`}>
        <div className={styles.content}>
          <div className={styles.text}>
            {heading && (
              <Heading level={2} className={styles.title}>
                {heading}
              </Heading>
            )}
          </div>

          {btn_label && (
            <Button variant="outlined" className={styles.button}>
              <LinkComponent
                type="internal"
                url={`/${language_code}/updates`}
                with_icon
              >
                {btn_label}
              </LinkComponent>
            </Button>
          )}
        </div>

        <List list={updatesToDisplay} />

        {/* <div className={styles.leaf}>
          <img src={leaf} alt="" />
        </div> */}
      </div>

      <img className={styles.topClip} src={TopClip} alt="" />
    </section>
  );
}
