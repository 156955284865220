import { graphql, Link, useStaticQuery } from "gatsby";
import dayjs from "dayjs";
import { useMemo } from "react";

import useDataSource from "@/base/hooks/useDataSource";
import Tag from "@/components/elements/Tag";
import Button from "@/components/elements/Button";
import Heading from "@/components/elements/Heading";
import LinkComponent from "@/components/elements/LinkComponent";
import MainScientificUpdateCard from "@/components/sections/RecentScientificUpdates/MainScientificUpdate";
import Image from "@/components/elements/Image";
import ImagePlaceholder from "@/components/elements/ImagePlaceholder";

import { useLanguageContext } from "@/base/context/LanguageContext";
import { getDownloadableResourceUrl } from "@/base/utils/getDownloadableResourceUrl";
import * as styles from "./styles.module.scss";

export function RecentScientificUpdates({
  heading,
  subheading,
  link_label,
  main_card,
  recent_cards,
}: RecentScientificUpdateType) {
  const data = useStaticQuery<{
    recentScientificUpdates: {
      edges: {
        node: StoryblokStory;
      }[];
    };
  }>(graphql`
    query RecentScientificUpdateQuery {
      recentScientificUpdates: allStoryblokEntry(
        filter: { field_component: { eq: "component_resource" } }
      ) {
        edges {
          node {
            uuid
            content
            full_slug
          }
        }
      }
    }
  `);

  const { getNameByValue } = useDataSource<CategoryType>("categories");

  const { language_code } = useLanguageContext();

  const initialData = useMemo(() => {
    const resourcesByLanguage = data.recentScientificUpdates?.edges
      ?.filter(
        (edge) => edge.node?.full_slug?.split("/")?.[0] === language_code
      )
      ?.slice(0, 3);

    const recentScientificUpdates =
      resourcesByLanguage?.map(({ node }) => ({
        ...(JSON.parse(node.content) as UpdatePageTemplate),
        full_slug: node.full_slug,
        uuid: node.uuid,
      })) || [];

    return { recentScientificUpdates };
  }, [data]);

  const mostRecentUpdate = initialData.recentScientificUpdates[0];
  let recentUpdates = initialData.recentScientificUpdates.slice(1);

  const mainCardData = initialData.recentScientificUpdates?.filter(
    (update) => update.uuid === main_card?.[0]
  );
  const recentCardsData = initialData.recentScientificUpdates?.filter(
    (update) => recent_cards?.includes(update.uuid)
  );

  recentUpdates = [...recentCardsData, ...recentUpdates].slice(0, 2);

  return (
    <section className={styles.wrapper}>
      <div className="container">
        <div className={styles.flex}>
          <div className={styles.content}>
            <Heading level={2} className={styles.title}>
              <LinkComponent
                url={`/${language_code}/resources`}
                type="internal"
              >
                {heading}
              </LinkComponent>
            </Heading>

            <p className={styles.subheading}>{subheading}</p>
          </div>

          {link_label && (
            <Button variant="outlined" className={styles.button}>
              <LinkComponent
                with_icon
                url={`/${language_code}/resources`}
                type="internal"
              >
                {link_label}
              </LinkComponent>
            </Button>
          )}
        </div>

        <div className={styles.posts}>
          <MainScientificUpdateCard
            {...(mainCardData?.[0] || mostRecentUpdate)}
            getNameByValue={getNameByValue}
            full_slug={getDownloadableResourceUrl(
              mainCardData?.[0] || mostRecentUpdate
            )}
          />

          <div className={styles.recentUpdates}>
            {recentUpdates.map((scientificUpdate) => (
              <div
                key={scientificUpdate._uid}
                className={styles.recentUpdatesCard}
              >
                <div className={styles.heroImage}>
                  {scientificUpdate?.image?.filename ? (
                    <img
                      src={scientificUpdate?.image?.filename}
                      alt=""
                      className={styles.thumbnail}
                    />
                  ) : (
                    <ImagePlaceholder />
                  )}
                </div>

                <div>
                  <div className={styles.details}>
                    <Link
                      to={getDownloadableResourceUrl(scientificUpdate)}
                      className={styles.link}
                      target="_blank"
                    >
                      <p className={styles.cardTitle}>
                        {scientificUpdate.title}
                      </p>

                      <span className={styles.date}>
                        {dayjs(scientificUpdate.su_date_of_publication).format(
                          "DD MMMM YYYY"
                        )}
                      </span>
                    </Link>

                    <div className={styles.categories}>
                      {Array.isArray(scientificUpdate.su_categories) && (
                        <Link
                          to={`/${language_code}/resources/${scientificUpdate.su_categories[0]}`}
                        >
                          <Tag
                            text={getNameByValue(
                              scientificUpdate.su_categories[0]
                            )}
                            size="small"
                            className={styles.cardFirstTag}
                          />
                        </Link>
                      )}

                      {/* <Tag text="..." size="small" /> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
