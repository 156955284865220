import { graphql, navigate, PageProps } from "gatsby";
import { useEffect } from "react";
import { useStoryblok } from "@/base/lib/storyblok";
import StoryblokComponent from "@/base/utils/connectSbComponent";
import { getLayoutData } from "@/base/helpers/getLayoutData";

import MainLayout from "@/components/layout/Main";
import { LanguageContextProvider } from "@/base/context/LanguageContext";
import { getLocaleFromPathname } from "@/base/Language/getLocaleFromPathname";
import { storyblokEditable } from "@storyblok/react";
import ProfessionalsOnlyPopup from "../sections/ProfessionalsOnlyPopup";

type PageData = {
  story: StoryblokStory;
  headers: StoryblokNodes;
  footers: StoryblokNodes;
  mainBanner: StoryblokStory;
};

export function Page({ data, location }: PageProps<PageData>) {
  const { story, headers, footers } = data;

  const locale = getLocaleFromPathname(location.pathname);

  const { header, footer } = getLayoutData(locale, headers, footers);

  const parsedStory = useStoryblok<PageTemplate>(story, location);

  const {
    content: { body, seo },
  } = parsedStory;

  useEffect(() => {
    // TODO: remove to release NL pages
    // As NL pages aren't meant to be released yet, we redirect to the BENL homepage
    if (
      locale === "nl" &&
      location?.origin === process.env.GATSBY_WEBSITE_ORIGIN
    ) {
      navigate("/benl/");
    }
  }, []);

  return (
    <LanguageContextProvider language_code={locale}>
      <ProfessionalsOnlyPopup />
      <MainLayout
        header={header}
        footer={footer}
        seo={seo}
        type="website"
        locale={locale}
      >
        {Array.isArray(body) &&
          body.map((section) => (
            <div key={section._uid} {...storyblokEditable(section)}>
              <StoryblokComponent blok={section} />
            </div>
          ))}
      </MainLayout>
    </LanguageContextProvider>
  );
}

export const pageQuery = graphql`
  query PageQuery($id: String!) {
    story: storyblokEntry(id: { eq: $id }) {
      id
      content
    }
    headers: allStoryblokEntry(
      filter: { field_component: { eq: "template_header" } }
    ) {
      nodes {
        full_slug
        content
      }
    }
    footers: allStoryblokEntry(
      filter: { field_component: { eq: "template_footer" } }
    ) {
      nodes {
        full_slug
        content
      }
    }
  }
`;

export default Page;
