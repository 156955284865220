import React from "react";

import Heading from "@/components/elements/Heading";
import RichText from "@/components/elements/RichText";
import CtaBlock from "@/components/elements/CtaBlock";
import * as styles from "./styles.module.scss";

export function RichTextWithCTA({
  title,
  content,
  cta,
}: Pick<PlantBasedEatingType, "content" | "cta" | "title">) {
  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.flexContainer}>
          <div className={styles.contentContainer}>
            <Heading level={1}>{title}</Heading>
            <div className={styles.richtextContainer}>
              <RichText content={content} />
            </div>
          </div>

          {cta?.length > 0 && (
            <div className={styles.ctaContainer}>
              {cta.map((item, index) => (
                <React.Fragment key={index}>
                  <CtaBlock cta={item} type={item.component} />
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
