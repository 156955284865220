import clsx from "clsx";
import Heading from "@/components/elements/Heading";
import { PaperFormEmbed } from "@/components/elements/PaperFormEmbed";

import * as styles from "./styles.module.scss";
interface Props {
  paperFormId: string;
}

export function PaperFormEmbedSection({ paperFormId }: Props) {
  if (!paperFormId) {
    return (
      <div className="container text-center">
        <Heading level={3}>
          Does not exist any form <br />
          with the given Paperform ID
        </Heading>
      </div>
    );
  }

  return (
    <div className={styles.paperFormContainer}>
      <div className={clsx(styles.backgroundImage, "fade-in")}>
        <PaperFormEmbed paperFormId={paperFormId} />
      </div>
    </div>
  );
}
