import { graphql, useStaticQuery } from "gatsby";
import { useMemo } from "react";
import VerticalCard from "@/components/elements/VerticalCard";
import { useFetchAllTemplatePages } from "@/base/hooks/useFetchAllTemplatePages";
import CtaCard from "@/components/elements/CtaCard";
import * as styles from "./styles.module.scss";

export function CardsGrid({ cards, cta_card }: CardsGridType) {
  const { parsedPages } = useFetchAllTemplatePages();

  const pagesToDisplay = parsedPages.filter((page) =>
    cards?.includes(String(page?.uuid))
  );

  return (
    <div className={`container ${styles.container}`}>
      <div className={styles.cardsGrid}>
        {pagesToDisplay?.map((page) => (
          <VerticalCard
            key={page?.title}
            className={styles.card}
            title={page?.title}
            excerpt={page?.excerpt}
            heroImage={{ image: page?.image?.filename }}
            url={page?.cta?.[0]?.url}
            ctaLabel={page?.cta?.[0]?.title}
            ctaType={page?.cta?.[0]?.type}
            variant="page-preview"
          />
        ))}
      </div>

      {cta_card?.[0] && (
        <CtaCard
          content_orientation={cta_card?.[0]?.content_orientation}
          title={cta_card?.[0]?.title}
          cta={cta_card?.[0]?.cta}
          image={cta_card?.[0]?.image}
          className={styles.ctaCardClass}
        />
      )}
    </div>
  );
}
