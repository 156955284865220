import { graphql, useStaticQuery } from "gatsby";
import { useMemo } from "react";

export function useFetchAllTemplatePages() {
  const data = useStaticQuery<{
    allPages: {
      edges: {
        node: PageTemplate;
      }[];
    };
  }>(graphql`
    query AllPagesQuery {
      allPages: allStoryblokEntry(
        filter: { field_component: { eq: "template_page" } }
        sort: { fields: field_su_date_of_publication_string, order: DESC }
      ) {
        edges {
          node {
            uuid
            content
            full_slug
          }
        }
      }
    }
  `);

  const { parsedPages } = useMemo(() => {
    const parsedPages = data.allPages.edges?.map((page) => ({
      ...page.node,
      ...(JSON.parse(page?.node?.content) as PageTemplate),
    }));

    return { parsedPages };
  }, [data]);

  return { parsedPages };
}
