// extracted by mini-css-extract-plugin
export var button = "styles-module--button--e19dc";
export var calendar = "styles-module--calendar--f921b";
export var clipPath = "styles-module--clip-path--02326";
export var content = "styles-module--content--cdf8b";
export var contentWrapper = "styles-module--contentWrapper--f69f2";
export var deadline = "styles-module--deadline--4afa6";
export var event = "styles-module--event--453c7";
export var img = "styles-module--img--ebd9e";
export var mask = "styles-module--mask--d47df";
export var wrapper = "styles-module--wrapper--1c865";