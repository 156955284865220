import Heading from "@/components/elements/Heading";
import Image from "@/components/elements/Image";

import * as styles from "./styles.module.scss";

export function HeroTextCentered({
  title,
  description,
  image_left: imageLeft,
  image_right: imageRight,
}: HeroTextCenteredType) {
  return (
    <section className={styles.wrapper}>
      <div className={styles.imageWrapper} data-position="left">
        {imageLeft && <Image fluid data={imageLeft} alt="" strategy="eager" />}
      </div>

      <div className="container">
        <div className={styles.content}>
          <Heading level={1} className={styles.title}>
            {title}
          </Heading>

          <p className={styles.description}>{description}</p>
        </div>
      </div>

      <div className={styles.imageWrapper} data-position="right">
        {imageRight && (
          <Image fluid data={imageRight} alt="" strategy="eager" />
        )}
      </div>
    </section>
  );
}
