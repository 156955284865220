import LinkComponent from "@/components/elements/LinkComponent";
import Button from "@/components/elements/Button";
import Heading from "@/components/elements/Heading";
import List from "@/components/elements/VerticalCardList";

import * as styles from "./styles.module.scss";

export function FeaturedScientificBoard({
  heading,
  subheading,
  btn_label,
  list,
}: FeaturedScientificBoardType) {
  const authorsList =
    Array.isArray(list) &&
    list
      .map((item) => ({
        key: item.content?.sa_name,
        title: item.content?.sa_name,
        excerpt: item.content?.sa_position,
        weight: item.content?.sa_weight ? parseInt(item.content?.sa_weight) : 0,
        url: `/${item.full_slug}`,
        heroImage: item.content?.sa_author_image,
      }))
      .sort((a, b) => a.weight - b.weight);
  return (
    <section className={styles.wrapper}>
      <div className="container">
        <div className={styles.content}>
          <div className={styles.text}>
            {heading && <Heading level={2}>{heading}</Heading>}
            {subheading && <span>{subheading}</span>}
          </div>
          {btn_label && (
            <Button variant="outlined">
              <LinkComponent type="internal" url="/scientific-board/" with_icon>
                {btn_label}
              </LinkComponent>
            </Button>
          )}
        </div>
        {authorsList && <List list={authorsList} />}
      </div>
    </section>
  );
}
