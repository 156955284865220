import Image from "../Image";
import LinkComponent from "../LinkComponent";
import * as styles from "./styles.module.scss";

export function CtaCard({
  content_orientation,
  cta,
  image,
  title,
  className,
}: CtaCardType) {

  return (
    <div className={`${styles.ctaCard} ${className}`} data-orientation={content_orientation}>
      {image && <Image className={styles.image} data={image} fluid />}

      <h5 className={styles.title} data-orientation={content_orientation}>
        {title}
      </h5>

      {cta?.[0] && (
        <LinkComponent
          {...cta[0]}
          variant="button_outlined"
          className={styles.cta}
        >
          {cta[0].title}
        </LinkComponent>
      )}
    </div>
  );
}
