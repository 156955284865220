import { useLocation } from "@reach/router";

// Write function for gatsby
export const useCurrentPaginationPage = () => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const currentPage = params.get("page");

  return { currentPage: Number(currentPage) };
};
