import { useRef } from "react";
import useClientPagination from "@/base/hooks/useClientPagination";
import ClientPagination from "@/components/elements/Pagination/ClientPagination";
import VerticalCard from "@/components/elements/VerticalCard";
import * as styles from "../styles.module.scss";

function NewsCard({ news }: { news: HitNews[] }) {
  return (
    <div className={styles.newsContainer} id="news-box">
      {news &&
        news.map((item: HitNews) => (
          <>
            <VerticalCard {...item} key={item.id} />
          </>
        ))}
    </div>
  );
}

export function PaginatedNews({
  itemsPerPage,
  newsArticles,
}: {
  itemsPerPage: number;
  newsArticles: HitNews[];
}) {
  const newsRef = useRef(null);

  const { paginatedArray, handlePageClick, pageCount, showPagination } =
    useClientPagination(newsArticles as any, newsRef, itemsPerPage);

  return (
    <div ref={newsRef}>
      <NewsCard news={paginatedArray as unknown as HitNews[]} />
      {showPagination && (
        <ClientPagination
          pageCount={pageCount}
          handlePageClick={handlePageClick}
        />
      )}
    </div>
  );
}
