import { graphql, useStaticQuery } from "gatsby";

import Heading from "@/components/elements/Heading";
import ResearchGrantCard from "@/components/elements/ResearchGrantCard";

import * as styles from "./styles.module.scss";

export function CurrentFundedResearch({
  title,
  description,
}: CurrentFundedResearchType) {
  const { grants } = useStaticQuery<{
    grants: {
      nodes: StoryblokStory[];
    };
  }>(graphql`
    query CurrentFundedResearchQuery {
      grants: allStoryblokEntry(
        filter: {
          field_component: { eq: "template_grant" }
          # field_gr_is_current_funded_boolean: { eq: true }
        } # sort: { fields: field_gr_date_string, order: DESC }
      ) {
        nodes {
          id
          full_slug
          content
        }
      }
    }
  `);

  const list = grants.nodes.map((item) => {
    const {
      gr_title,
      gr_author,
      gr_university,
      gr_description,
      gr_card_image,
    } = JSON.parse(item.content);

    return {
      id: item.id,
      title: gr_title,
      author: gr_author,
      university: gr_university,
      excerpt: gr_description,
      heroImage: gr_card_image,
    };
  });

  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.contentWrapper}>
          <Heading level={2}>{title}</Heading>
          <p>{description}</p>
          <div className={styles.cardContainer}>
            {list.map((item) => (
              <ResearchGrantCard key={item.id} {...item} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
