import { graphql, useStaticQuery } from "gatsby";

import useDataSource from "@/base/hooks/useDataSource";
import HorizontalCard from "@/components/elements/HorizontalCard";

import { winnersSortedByDate } from "@/base/helpers/winnersSortedByDate";
import Heading from "@/components/elements/Heading";

export const AwardWinners = () => {
  const { site, awardWinners } = useStaticQuery<
    {
      site: {
        siteMetadata: {
          ogImage: string;
        };
      };
    } & {
      awardWinners: {
        nodes: {
          content: string;
          full_slug: string;
        }[];
      };
    }
  >(graphql`
    query AwardsQuery {
      site {
        siteMetadata {
          ogImage
        }
      }

      awardWinners: allStoryblokEntry(
        filter: {
          # field_gr_is_current_funded_boolean: { nin: true }
          field_component: { eq: "template_scientificUpdate" }
        }
      ) {
        nodes {
          content
          full_slug
        }
      }
    }
  `);

  const winners = awardWinners.nodes
    .map((item) => {
      const content = {
        ...JSON.parse(item.content),
      };
      const slug = { slug: `/${item.full_slug}` };
      return { ...content, ...slug };
    })
    .filter((awardWinner) => awardWinner.su_labels.includes("award"));

  const { getNameByValue } = useDataSource<CategoryType>("categories");

  const { latestWinners, previousWinners } = winnersSortedByDate(winners);

  return (
    <>
      {latestWinners.length > 0 && (
        <Heading level={2}>Latest award winners</Heading>
      )}
      {latestWinners?.map((result) => (
        <HorizontalCard
          key={result.su_title}
          publishedDate={result.su_date_of_publication}
          title={result.su_title}
          excerpt={result.su_excerpt}
          labels={result.su_labels}
          heroImage={result.su_hero_img || site.siteMetadata.ogImage}
          categories={result.su_categories}
          url={result?.slug}
          getNameByValue={getNameByValue}
          className="awards-card"
        />
      ))}
      {previousWinners.length > 0 && (
        <Heading level={2}>Previous award winners</Heading>
      )}
      {previousWinners?.map((result) => (
        <HorizontalCard
          key={result.id}
          publishedDate={result.su_date_of_publication}
          title={result.su_title}
          excerpt={result.su_excerpt}
          labels={result.su_labels}
          heroImage={result.su_hero_img || site.siteMetadata.ogImage}
          categories={result.su_categories}
          url={result?.slug}
          getNameByValue={getNameByValue}
          className="awards-card"
        />
      ))}
    </>
  );
};
