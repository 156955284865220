import CtaCard from "@/components/elements/CtaCard";

import * as styles from "./styles.module.scss";

export function CtaCardsList({ cards }: CtaCardsListType) {
  return (
    <div className={styles.ctaCardsList}>
      {cards?.length > 0 &&
        cards.map((card) => <CtaCard {...card} key={card?.title} />)}
    </div>
  );
}
