import Accordion from "@/components/elements/Accordion";
import CtaCard from "@/components/elements/CtaCard";
import * as styles from "./styles.module.scss";

export function Accordions({ title, accordions, cta_card }: AccordionsType) {
  return (
    <div className={styles.accordionsSection}>
      {title && <p className={styles.title}>{title}</p>}

      <div className={styles.accordions}>
        {accordions?.map((accordion) => (
          <Accordion
            {...accordion}
            key={accordion.title}
            className={styles.accordion}
          />
        ))}

        {cta_card?.[0] && (
          <div className={styles.ctaCardWrapper}>
            <CtaCard {...cta_card?.[0]} className={styles.ctaCard} />
          </div>
        )}
      </div>
    </div>
  );
}
