export const getDownloadableResourceUrl = (resource: any) => {
  if (resource?.type === "pdf") {
    return resource?.pdf?.filename;
  }

  if (resource?.type === "mp4") {
    return resource?.video?.filename;
  }

  return false;
};
