// extracted by mini-css-extract-plugin
export var cta = "styles-module--cta--58807";
export var descriptionColumn = "styles-module--description-column--181bf";
export var disclaimer = "styles-module--disclaimer--c3368";
export var form = "styles-module--form--c3b5d";
export var icon = "styles-module--icon--a62a3";
export var listItem = "styles-module--listItem--9154b";
export var listTitle = "styles-module--listTitle--2d214";
export var newsletterSection = "styles-module--newsletter-section--b1225";
export var subheading = "styles-module--subheading--bddd6";
export var title = "styles-module--title--76c61";