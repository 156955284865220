// @ts-nocheck
import sub from "date-fns/sub";
import { useEffect, useState } from "react";
import { useAlgoliaIndex } from "@/base/hooks/useAlgoliaIndex";

const currentDate = new Date();
const backSixMonthsFromNow = sub(currentDate, { months: 6 }).getTime();

export function usePaginatedNews() {
  const [searchResults, setSearchResults] = useState<
    [Array<HitNews>, number] | undefined
  >(undefined);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const { isIndexReady, index } = useAlgoliaIndex();

  function fetchResults() {
    if (isIndexReady && index) {
      index
        .search("", {
          page,
          // (scientific updates and events) going back six months
          filters: `(gr_finished = 0 OR NOT gr_finished = 1) AND dateTimestamp >= ${backSixMonthsFromNow}`,
        })
        .then((res) => {
          if (res.page !== page) {
            setPage(res.page);
          }
          setTotalPages(res.nbPages);

          setSearchResults([res.hits as unknown as HitNews[], res.nbHits]);
        })
        // eslint-disable-next-line no-console
        .catch(console.log);
    }
  }

  useEffect(() => {
    fetchResults();
  }, [page]);

  return {
    searchResults,
    setPage,
    page,
    totalPages,
  };
}
