import clsx from "clsx";

import * as inputStyles from "../Input/styles.module.scss";
import * as styles from "./styles.module.scss";

interface SelectProps {
  labelText?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  items: Array<{
    value: string;
    label: string;
  }>;
  error?: string | boolean;
}

export function Select({
  items,
  value,
  onChange,
  labelText,
  error,
  ...rest
}: SelectProps) {
  return (
    <label className={inputStyles.labelContainer}>
      {labelText && <p className={inputStyles.labelText}>{labelText}</p>}
      <div className={styles.selectContainer}>
        <select
          value={value}
          onChange={onChange}
          className={clsx(
            {
              [styles.errorSelect]: !!error,
            },
            styles.select
          )}
          {...rest}
        >
          {items.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </label>
  );
}
