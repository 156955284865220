import { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import useClientPagination from "@/base/hooks/useClientPagination";
import { useLanguageContext } from "@/base/context/LanguageContext";
import HeaderScientificUpdate from "../ScientificUpdateList/HeaderScientificUpdate";
import ResourceList from "./ResourceList";
import * as styles from "./styles.module.scss";

const ITEMS_PER_PAGE = 6;

const useResourceList = (resources, category) => {
  const ref = useRef(null);
  const { t, language_code } = useLanguageContext();

  if (!resources?.[category]) {
    return null;
  }

  const { paginatedArray } = useClientPagination(
    resources[category],
    ref,
    ITEMS_PER_PAGE
  );

  return (
    <div ref={ref}>
      <ResourceList
        title={t([`resources.${category}`])}
        list={paginatedArray}
        isFeaturedCardDisplayed={true}
        cta={{
          label: t`resources.see-all` + " " + t([`resources.${category}`]),
          // TODO: hardcoded url
          url: `/${language_code}/resources/${category}`,
        }}
      />
    </div>
  );
};

export function Resources() {
  const { language_code } = useLanguageContext();

  const data = useStaticQuery(graphql`
    query ResourcesQuery {
      resources: allStoryblokEntry(
        filter: { field_component: { eq: "component_resource" } }
        sort: { fields: field_su_date_of_publication_string, order: DESC }
      ) {
        nodes {
          full_slug
          content
          field_su_date_of_publication_string
        }
      }
    }
  `);

  const resourcesByLanguage = data?.resources?.nodes?.filter(
    (node) => node.full_slug?.split("/")?.[0] === language_code
  );

  const resourcesAll = resourcesByLanguage?.map((node) =>
    JSON.parse(node.content)
  );

  const resourcesByCategory = resourcesAll.reduce((acc, resource) => {
    const category = resource.category;

    acc[category] = [...(acc[category] || []), resource];

    return acc;
  }, {});

  // TODO: hardcoded categories
  const resourcesForHealthcareProfessionals = useResourceList(
    resourcesByCategory,
    "for-healthcare-professionals"
  );
  const resourcesForYourPatients = useResourceList(
    resourcesByCategory,
    "for-your-patients"
  );
  const resourcesVideos = useResourceList(
    resourcesByCategory,
    "videos-webinars-podcasts-and-e-learnings"
  );

  return (
    <section className={styles.section}>
      <HeaderScientificUpdate dataSourceCategory="resources" />
      {resourcesForHealthcareProfessionals}
      {resourcesForYourPatients}
      {resourcesVideos}
    </section>
  );
}
