import clsx from "clsx";
import { ReactElement } from "react";
import bgGreen from "@/assets/svgs/sceintific-update-bg.svg";
import bgYellow from "@/assets/svgs/scientific-author-bg.svg";

import * as styles from "./styles.module.scss";

interface ClipPathProps {
  children: ReactElement;
  className?: string;
  version?: 1 | 2 | 3;
}

export function ClipPath({ children, className, version = 1 }: ClipPathProps) {
  const renderPath = () => {
    switch (version) {
      case 2:
      case 3:
        return "M0.423,0.989 C0.456,0.987,0.488,0.996,0.519,0.993 C0.638,0.983,0.795,1,0.896,0.967 C0.941,0.949,0.949,0.918,0.959,0.89 C1,0.759,1,0.621,1,0.488 C1,0.408,0.998,0.329,0.992,0.249 C0.987,0.18,0.995,0.125,0.963,0.059 C0.929,-0.009,0.792,-0.001,0.7,0.002 C0.544,0.006,0.387,0.001,0.231,0.008 C0.167,0.011,0.037,0.023,0.037,0.073 C0.035,0.195,0.012,0.319,0.022,0.44 C0.029,0.529,0.006,0.618,0.006,0.707 C0.007,0.757,-0.034,0.98,0.088,0.994 C0.198,1,0.312,0.995,0.423,0.989";
      case 1:
      default:
        return "M0.99,0.579 C0.988,0.547,0.997,0.515,0.994,0.483 C0.984,0.364,1,0.208,0.968,0.106 C0.95,0.062,0.919,0.054,0.891,0.044 C0.76,-0.002,0.622,0.003,0.489,0.003 C0.409,0.002,0.33,0.005,0.25,0.01 C0.181,0.015,0.127,0.007,0.06,0.04 C-0.008,0.073,0,0.211,0.003,0.302 C0.008,0.459,0.002,0.615,0.009,0.771 C0.012,0.835,0.024,0.965,0.074,0.966 C0.196,0.967,0.32,0.991,0.441,0.981 C0.53,0.973,0.619,0.997,0.708,0.996 C0.758,0.996,0.981,1,0.996,0.915 C1,0.804,0.996,0.691,0.99,0.579";
    }
  };

  return (
    <>
      <svg className={styles.svgContainer}>
        <clipPath id="clip-path" clipPathUnits="objectBoundingBox">
          <path d={renderPath()}></path>
        </clipPath>
      </svg>
      <div className={clsx(styles.clipPath, className, "clip-path-container")}>
        {version === 1 && (
          <img
            className={clsx(styles.backgroundImage, styles.bgGreen)}
            src={bgGreen}
            alt="background"
          />
        )}
        {version === 3 && (
          <img
            className={clsx(styles.backgroundImage, styles.bgYellow)}
            src={bgYellow}
            alt="background"
          />
        )}
        <div className={clsx(styles.clipped, "clipped")}>{children}</div>
      </div>
    </>
  );
}
