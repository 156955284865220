// extracted by mini-css-extract-plugin
export var button = "styles-module--button--a703f";
export var cardTitle = "styles-module--cardTitle--d3068";
export var categories = "styles-module--categories--c0be5";
export var content = "styles-module--content--a9fe5";
export var date = "styles-module--date--207e7";
export var details = "styles-module--details--93b93";
export var flex = "styles-module--flex--9a5dd";
export var heroImage = "styles-module--hero-image--90f66";
export var link = "styles-module--link--3628c";
export var posts = "styles-module--posts--40293";
export var recentUpdates = "styles-module--recent-updates--c2fdd";
export var recentUpdatesCard = "styles-module--recent-updates-card--a2ee1";
export var subheading = "styles-module--subheading--3de2e";
export var thumbnail = "styles-module--thumbnail--718d4";
export var title = "styles-module--title--c05c8";
export var wrapper = "styles-module--wrapper--adc1d";