import { useEffect } from "react";
import Button from "@/components/elements/Button";
import Heading from "@/components/elements/Heading";
import LinkComponent from "@/components/elements/LinkComponent";
import NewsletterForm from "@/components/elements/NewsletterForm";
import RemarkRichTextComponents from "@/components/elements/RichText";

import Icon from "@/components/elements/Icon";

import * as styles from "./styles.module.scss";

const PaperformForm = ({ id }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://paperform.co/__embed.min.js";
    document.body.appendChild(script);
  }, []);

  return <div data-paperform-id={id} data-spinner="1" />;
};

export function Newsletter({
  actions,
  heading,
  subheading,
  list,
  paperform_id,
  disclaimer,
  disclaimer_rich_text,
}: NewsletterSectionType) {
  return (
    <section className={styles.newsletterSection}>
      <div className="container">
        <div className={styles.descriptionColumn}>
          <Heading level={2} className={styles.title}>
            {heading || "Subscribe to our newsletter"}
          </Heading>

          {list && list.length > 0 && (
            <>
              <p className={styles.listTitle}>{list[0].title}</p>
              <ul>
                {list[0].list.map((item, index) => (
                  <li key={`item-${index}`} className={styles.listItem}>
                    <Icon name="circleCheckmark" className={styles.icon} />
                    <RemarkRichTextComponents content={item.text} />
                  </li>
                ))}
              </ul>
            </>
          )}

          {subheading ? (
            <p className={styles.subheading}>{subheading}</p>
          ) : null}

          {Array.isArray(actions) &&
            actions.map((item) => (
              <Button variant="outlined" key={item._uid}>
                <LinkComponent
                  url={item.url}
                  type={item.type}
                  with_icon={item.with_icon}
                  className={styles.cta}
                >
                  {item.title}
                </LinkComponent>
              </Button>
            ))}
        </div>

        {paperform_id && (
          <div className={styles.form}>
            <PaperformForm id={paperform_id} />
            {disclaimer && !disclaimer_rich_text && (
              <p className={styles.disclaimer}>{disclaimer}</p>
            )}
            {disclaimer_rich_text && (
              <RemarkRichTextComponents content={disclaimer_rich_text} />
            )}
          </div>
        )}

        {/* <NewsletterForm /> */}
      </div>
    </section>
  );
}
