import { useMemo } from "react";
import axios from "axios";
import clsx from "clsx";
import { Field, FieldProps, Formik, FormikHelpers } from "formik";
import { lazy, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import Yup from "@/base/utils/yup-extended";

import { useLanguageContext } from "@/base/context/LanguageContext";
import checkmark from "@/assets/svgs/checkmark.svg";
import {
  plausibleEventsAvailable,
  plausibleTrack,
} from "@/base/utils/plausible";
import { countries as _countries } from "@/base/utils/countries";
import { professions as _professions } from "@/base/utils/professions";
import Button from "@/components/elements/Button";
import Input from "@/components/elements/Input";
import Select from "@/components/elements/Select";
import LazyIntersectionObserver from "../LazyIntersectionObserver";
import * as styles from "./styles.module.scss";
import { FormValues, ContactUsAPIResponse } from "./types";

function getValidationSchema(t) {
  return Yup.object().shape({
    first_name: Yup.string().required(t`contact_us.form.required_field`),
    last_name: Yup.string().required(t`contact_us.form.required_field`),
    email: Yup.string()
      .email(t`contact_us.form.email.validation_error`)
      .required(t`contact_us.form.required_field`)
      .emailExists(t`contact_us.form.email.existing_email`),
    question: Yup.string().required(t`contact_us.form.required_field`),
  });
}

export function ContactUsForm() {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [errorMsg, setErrorMsg] = useState<boolean | string>(false);

  const { t } = useLanguageContext();

  const newsletterSchema = getValidationSchema(t);

  // const recaptchaRef = useRef(null);

  // const [ref, inView] = useInView({
  //   triggerOnce: true,
  // });

  // const GoogleRecaptcha = lazy(
  //   () => import("@/components/elements/GoogleRecaptcha/GoogleRecaptcha")
  // );

  // const LazyGoogleRecaptchaComponent = () => {
  //   return (
  //     <div className={styles.recaptchaContainer}>
  //       <GoogleRecaptcha recaptchaRef={recaptchaRef} />
  //     </div>
  //   );
  // };

  const professions = useMemo(() => {
    return _professions.map((profession) => {
      return {
        value: profession.value,
        label: t([`professions.${profession.value}`]),
      };
    });
  }, [_professions]);

  const initialValues: FormValues = {
    email: "",
    first_name: "",
    last_name: "",
    question: "",
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const body = {
      Email: values.email,
      Name: `${values.first_name} ${values.last_name}`,
      CustomFields: [`Question=${values.question}`],
    };

    // const recaptchaValue =
    //   recaptchaRef.current && recaptchaRef.current.getValue();

    // if (!recaptchaValue) {
    //   plausibleTrack({
    //     eventName: plausibleEventsAvailable["sign-up-newsletter-form"],
    //     props: {
    //       location: window.location.pathname,
    //       result: "Click ended with captcha error",
    //     },
    //   });
    //   setErrorMsg("reCAPTCHA verification is required.");
    //   return;
    // }

    try {
      const response: ContactUsAPIResponse = await axios.post(
        "https://api.moosend.com/v3/subscribers/8bf6c9eb-6d7a-4991-8660-4345f52231d7/subscribe.json?apikey=1157f7c1-3d3a-4414-96d1-c930f4729d0c",
        body,
        {
          headers,
        }
      );
      // code 0 is success
      if (response.status === 200) {
        const allEmails = JSON.parse(
          localStorage.getItem("signedEmails") || "[]"
        );
        switch (response.data.Code) {
          case 0:
            setIsSubscribed(true);
            setErrorMsg(false);
            localStorage.setItem(
              "signedEmails",
              JSON.stringify([...allEmails, response.data.Context.Email])
            );
            plausibleTrack({
              eventName: plausibleEventsAvailable["sign-up-newsletter-form"],
              props: {
                location: window.location.pathname,
                result: "Click ended with a successful subscribe",
              },
            });
            break;
          case 429:
            setErrorMsg("Too many requests. Please try again later.");
            break;
          default:
            setIsSubscribed(false);
            setErrorMsg("Something went wrong. Please try again later.");
        }
      }
      setSubmitting(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <div className={styles.newsletterContainer}>
      {isSubscribed ? (
        <div
          className={clsx(
            {
              [styles.newsletterSubscribed]: isSubscribed,
            },
            styles.newsletterForm
          )}
        >
          <div className={styles.checkmark}>
            <img src={checkmark} alt="checkmark" />
          </div>
          <p className={styles.subscribed}>{t`contact_us.form.success`}</p>
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={newsletterSchema}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className={styles.newsletterForm}>
              <Field name="profession">
                {({ field, meta }: FieldProps) => (
                  <Select
                    labelText={t`contact_us.form.profession.label`}
                    error={meta.touched && meta.error}
                    items={[
                      {
                        value: "",
                        label: t`contact_us.form.profession.placeholder`,
                      },
                      ...professions,
                    ]}
                    {...field}
                  />
                )}
              </Field>
              <div className={styles.nameContainer}>
                <div>
                  <Field type="text" name="first_name">
                    {({ field, meta, form: { isSubmitting } }: FieldProps) => (
                      <Input
                        type="text"
                        placeholder={t`contact_us.form.first_name`}
                        labelText={t`contact_us.form.first_name`}
                        error={meta.touched && meta.error && meta.error}
                        disabled={isSubmitting}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
                <div>
                  <Field name="last_name">
                    {({ field, meta, form: { isSubmitting } }: FieldProps) => (
                      <Input
                        type="text"
                        placeholder={t`contact_us.form.last_name`}
                        labelText={t`contact_us.form.last_name`}
                        error={meta.touched && meta.error && meta.error}
                        disabled={isSubmitting}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <Field name="email">
                {({ field, meta, form: { isSubmitting } }: FieldProps) => (
                  <Input
                    type="email"
                    placeholder={t`contact_us.form.email.placeholder`}
                    labelText={t`contact_us.form.email.label`}
                    error={meta.touched && meta.error && meta.error}
                    disabled={isSubmitting}
                    {...field}
                  />
                )}
              </Field>
              <Field name="question">
                {({ field, meta, form: { isSubmitting } }: FieldProps) => (
                  <Input
                    type="textarea"
                    placeholder={t`contact_us.form.question.placeholder`}
                    labelText={t`contact_us.form.question.label`}
                    error={meta.touched && meta.error && meta.error}
                    disabled={isSubmitting}
                    {...field}
                  />
                )}
              </Field>

              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting || isSubscribed}
              >
                {t`contact_us.form.submit_button`}
              </Button>

              {errorMsg && <p className={styles.errorMessage}>{errorMsg}</p>}

              <p
                className={styles.unsubscribe}
              >{t`contact_us.form.disclaimer`}</p>

              {/* <div
                style={{
                  marginTop: 24,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LazyIntersectionObserver
                  Component={GoogleRecaptcha}
                  inView={inView}
                  ref={ref}
                />
              </div> */}
            </form>
          )}
        </Formik>
      )}
    </div>
  );
}
