import { useState } from "react"
import * as styles from "./styles.module.scss"
import RichText from "@/components/elements/RichText";

interface Props {
  title: string,
  content: StoryblokRichtext,
  className?: string,
}

export function Accordion({ title, content, className }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`${styles.accordion} ${className}`}>
      <div className={styles.labelContainer} onClick={() => setIsOpen(isOpen => !isOpen)}>
        <p className={styles.label}>{title}</p>
      
        <h3 className={styles.icon}>{isOpen ? "-" : "+"}</h3>
      </div>
      

      {isOpen && (
        <p className={styles.content}>
          <RichText content={content} />
        </p>
      )}
    </div>
  )
} 