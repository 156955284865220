/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ReactNode, useEffect, useRef, useState } from "react";
// eslint-disable-next-line import/order
import { useLocation } from "@reach/router";
import clsx from "clsx";

import useDataSource from "@/base/hooks/useDataSource";
import Heading from "@/components/elements/Heading";
import LinkComponent from "@/components/elements/LinkComponent";

import { useLanguageContext } from "@/base/context/LanguageContext";
import * as styles from "./styles.module.scss";
import { DatasourcesName } from "./enum";

interface HeaderScientificUpdateProps
  extends Partial<Pick<ScientificUpdateListType, "heading" | "subheading">> {
  children?: ReactNode;
  className?: string;
  setPage?: (page: number) => void;
  dataSourceCategory: DataSourceSlug;
}

export function HeaderScientificUpdate({
  heading,
  subheading,
  children,
  className,
  setPage,
  dataSourceCategory,
}: HeaderScientificUpdateProps) {
  const { dataSource } = useDataSource<CategoryType>(dataSourceCategory);

  const location = useLocation();
  const { t, language_code } = useLanguageContext();

  const [isNavbarScrolled, setIsNavbarScrolled] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (containerRef.current) {
      if (position > 25) {
        setIsNavbarScrolled(true);
      } else {
        setIsNavbarScrolled(false);
      }
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function findActiveCategory() {
    if (dataSource.length > 0) {
      const { pathname } = location;
      const activeCategory = dataSource.find(({ value }) =>
        pathname.includes(value)
      );
      return activeCategory?.value || "all";
    }
    return "all";
  }

  const absolutePath = location.pathname.includes(DatasourcesName.scientific)
    ? DatasourcesName.scientific
    : DatasourcesName.resources;

  return (
    <>
      <div
        className={clsx(styles.categoriesContainer, className)}
        ref={containerRef}
      >
        <div className="container">
          {children ? (
            children
          ) : (
            <>
              <Heading level={1}>{heading}</Heading>
              <p>{subheading}</p>
            </>
          )}
        </div>
      </div>

      <div
        className={clsx(styles.tabs, {
          [styles.scrolled]: isNavbarScrolled,
        })}
      >
        <div className="container">
          <ul className={styles.linksContainer}>
            <li
              className={clsx({
                [styles.active]: findActiveCategory() === "all",
              })}
              onClick={() => setPage?.(0)}
            >
              <LinkComponent
                type="internal"
                url={`/${language_code}/${absolutePath}`}
              >
                {t`resources.all`}
              </LinkComponent>
            </li>

            {dataSource.map((item) => (
              <li
                key={item.name}
                className={clsx({
                  [styles.active]: findActiveCategory() === item.value,
                })}
                onClick={() => setPage?.(0)}
              >
                <LinkComponent
                  type="internal"
                  url={`/${language_code}/${absolutePath}/${item.value?.replace(
                    /_/g,
                    "-"
                  )}`}
                >
                  {t([`resources.${item.value}`])}
                </LinkComponent>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
