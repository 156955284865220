import Markdown, { MarkdownToJSX } from "markdown-to-jsx";
import Heading from "../Heading";

export function MarkdownComponent({
  content,
  className,
  overrides,
}: {
  content: string;
  className?: string;
  overrides?: MarkdownToJSX.Overrides;
}) {
  return (
    <div className={className}>
      <Markdown
        options={{
          overrides: {
            h1: {
              component: ({ children }) => (
                <Heading level={1}>{children}</Heading>
              ),
            },
            h2: {
              component: ({ children }) => (
                <Heading level={2}>{children}</Heading>
              ),
            },
            h3: {
              component: ({ children }) => (
                <Heading level={3}>{children}</Heading>
              ),
            },
            h4: {
              component: ({ children }) => (
                <Heading level={4}>{children}</Heading>
              ),
            },
            ...overrides,
          },
          forceBlock: true,
        }}
      >
        {content}
      </Markdown>
    </div>
  );
}
