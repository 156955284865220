import useDataSource from "@/base/hooks/useDataSource";
import VerticalCard from "@/components/elements/VerticalCard";
import * as styles from "./styles.module.scss";

type VerticalCardListProps = {
  list?: (CardDataType & { key: string })[];
};
export function VerticalCardList({ list }: VerticalCardListProps) {
  const { getNameByValue } = useDataSource<CategoryType>("categories");

  return (
    <div className={styles.featuredList}>
      {Array.isArray(list) &&
        list.map((item) => (
          <VerticalCard
            {...item}
            getNameByValue={getNameByValue}
            key={item.key}
          />
        ))}
    </div>
  );
}
