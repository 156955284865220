import { RemarkRichTextComponents } from "@/components/elements/RichText/RichText";
import LinkComponent from "@/components/elements/LinkComponent";
import IconCard from "@/components/elements/IconCard";
import * as styles from "./styles.module.scss";

export function IconCardsList({
  title,
  content,
  cta,
  cards,
}: IconCardsListType) {
  return (
    <div className={styles.iconCardsList}>
      <div className={styles.top}>
        <div className={styles.textContent}>
          <h2 className={styles.title}>{title}</h2>

          <div className={styles.description}>
            {content && <p>{RemarkRichTextComponents({ content })}</p>}
          </div>
        </div>

        {cta?.[0] && (
          <LinkComponent
            {...cta[0]}
            variant="button_outlined"
            className={styles.cta}
          >
            {cta[0].title}
          </LinkComponent>
        )}
      </div>

      <div className={styles.cards}>
        {cards?.length &&
          cards?.map((card) => <IconCard key={card?.title} {...card} />)}
      </div>
    </div>
  );
}
