import LinkComponent from "@/components/elements/LinkComponent";
import RemarkRichTextComponents from "@/components/elements/RichText";

import { plausibleEventsAvailable } from "@/base/utils/plausible";
import Image from "@/components/elements/Image";
import * as styles from "./styles.module.scss";

export function MainHero({
  label,
  content,
  actions,
  left_image,
  right_image,
  mobile_image,
}: MainHeroType) {
  const images = [
    {
      img: left_image,
      className: styles.leftImage,
      visible: Boolean(left_image.image),
    },
    {
      img: right_image,
      className: styles.rightImage,
      visible: Boolean(right_image.image),
    },
    {
      img: mobile_image,
      className: styles.mobileImage,
      visible: Boolean(mobile_image.image),
    },
  ];

  const actionButtonUrl = (actionBtn: Pick<LinkElement, "type" | "url">) => {
    try {
      if (actionBtn.type === "internal") return actionBtn.url;
      return new URL(actionBtn.url)?.pathname;
    } catch (error) {
      return process.env.GATSBY_URL;
    }
  };

  return (
    <section className={styles.heroSection}>
      <div className={styles.content}>
        {label && <span className={styles.subtitle}>{label}</span>}
        {content && (
          <div className={styles.description}>
            <RemarkRichTextComponents content={content} />
          </div>
        )}

        {Array.isArray(actions) && actions.length > 0 && (
          <div className={styles.actionButtons}>
            {actions.map((actionBtn, index) => (
              <LinkComponent
                dataAnalytics={{
                  eventName:
                    plausibleEventsAvailable["hero-promo-button-click"],
                  props: { action: `Click on: ${actionBtn.title}` },
                }}
                variant={index === 0 ? "button_primary" : "button_outlined"}
                key={actionBtn._uid}
                {...actionBtn}
                url={actionButtonUrl(actionBtn) ?? ""}
              >
                {actionBtn.title}
              </LinkComponent>
            ))}
          </div>
        )}
      </div>

      <div className={styles.images}>
        {images
          .filter(({ visible }) => visible)
          .map(({ img, className }) => {
            if (!img) return null;
            return (
              <div key={img._uid} className={className}>
                <Image
                  fluid
                  data={img}
                  alt={img?.alt_tag || ""}
                  strategy="eager"
                />
              </div>
            );
          })}
      </div>
    </section>
  );
}
