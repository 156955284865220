import { graphql, useStaticQuery } from "gatsby";
import { useMemo } from "react";

import Heading from "@/components/elements/Heading";
import List from "@/components/elements/VerticalCardList";
import beans from "@/assets/images/beans-bg.png";
import leafFull from "@/assets/images/leaf-full.png";
import * as styles from "./styles.module.scss";

export function ScientificBoardList({
  label,
  heading,
  subheading,
  list_title,
}: ScientificBoardListType) {
  const data = useStaticQuery<{
    scientificBoard: {
      edges: {
        node: StoryblokStory;
      }[];
    };
  }>(graphql`
    query AllScientificBoardQuery {
      scientificBoard: allStoryblokEntry(
        filter: { field_component: { eq: "template_scientificAuthor" } }
      ) {
        edges {
          node {
            content
            full_slug
          }
        }
      }
    }
  `);

  const initialData = useMemo(() => {
    const scientificBoard =
      !!data && data.scientificBoard
        ? data.scientificBoard.edges.map(({ node }) => ({
            ...(JSON.parse(node.content) as ScientificAuthorPageTemplate),
            full_slug: node.full_slug,
          }))
        : [];
    return { scientificBoard };
  }, [data]);

  const authorsList = initialData.scientificBoard
    ?.map((item) => ({
      key: item._uid,
      title: item.sa_name,
      excerpt: item.sa_position,
      url: `/${item.full_slug}`,
      heroImage: item.sa_author_image,
      weight: item.sa_weight ? parseInt(item.sa_weight) : 0,
    }))
    .sort((a, b) => a.weight - b.weight);

  return (
    <section className={styles.wrapper}>
      <img src={beans} alt="beans" className={styles.beansBg} />
      <img src={leafFull} alt="leaf" className={styles.topLeaf} />
      <div className="container">
        <div className={styles.headingContent}>
          {label && <span className={styles.label}>{label}</span>}
          <Heading level={1}>{heading}</Heading>
          <p className={styles.subheading}>{subheading}</p>
        </div>
        <div className={styles.boardWrapper}>
          <Heading level={2} className={styles.title}>
            {list_title}
          </Heading>
          <List list={authorsList} />
        </div>
      </div>
    </section>
  );
}
