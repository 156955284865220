export const professions = [
  {
    value: "dietitian",
    label: "Dietitian",
  },
  {
    value: "doctor",
    label: "Medical doctor",
  },
  {
    value: "nutritionist",
    label: "Nutritionist",
  },
  {
    value: "researcher",
    label: "Researcher",
  },
  {
    value: "student",
    label: "Student",
  },
  {
    value: "other",
    label: "Other",
  },
];
