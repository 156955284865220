import clsx from "clsx";

import truncate from "@/base/helpers/truncateString";
import ClipPath from "@/components/elements/ClipPath";
import Heading from "@/components/elements/Heading";
import ImagePlaceholder from "@/components/elements/ImagePlaceholder";
import Image from "@/components/elements/Image";

import * as styles from "./styles.module.scss";

export function ResearchGrantCard({
  heroImage,
  title,
  author,
  university,
  excerpt,
  className,
}: ResearchGrantCardDataType) {
  return (
    <div className={clsx(styles.cardWrapper, className)}>
      <div className={styles.imageWrapper}>
        <ClipPath version={2} className={styles.clipPath}>
          <>
            {heroImage?.image ? (
              <Image data={heroImage} fluid />
            ) : typeof heroImage === "string" ? (
              <img
                src={heroImage}
                alt="card img"
                className={styles.heroImage}
              />
            ) : (
              <ImagePlaceholder />
            )}
          </>
        </ClipPath>
      </div>

      <div className={styles.content}>
        <Heading level={3}>{title}</Heading>
        {(author || university) && (
          <p>
            {author && <div>{author}</div>}
            {university && <div>{university}</div>}
          </p>
        )}
        {excerpt && <p>{excerpt}</p>}
      </div>
    </div>
  );
}
