import dayjs from "dayjs";

import RichText from "@/components/elements/RichText";

import Heading from "@/components/elements/Heading";
import LinkComponent from "@/components/elements/LinkComponent";
import { AwardWinners } from "./components/AwardWinners";
import * as styles from "./styles.module.scss";

export function AwardsHero({
  hero_content,
  promo_title,
  promo_deadline,
  promo_description,
  promo_cta_url,
  promo_cta_text,
}: AwardsType) {
  const hasPromo = !!promo_cta_text ?? !!promo_description;

  return (
    <section className={styles.section} data-promo={String(hasPromo)}>
      <div className="container">
        <div className={styles.heroContainer}>
          <RichText content={hero_content} />
          <div className={styles.imageWrapper}>
            {promo_title && promo_cta_url?.cached_url && promo_deadline && (
              <div className={styles.promoBox} data-promo={String(hasPromo)}>
                <span>
                  🎯 Deadline: {dayjs(promo_deadline).format("DD MMMM YYYY")}
                </span>
                <Heading level={3}>{promo_title}</Heading>
                <p>{promo_description}</p>

                <LinkComponent
                  variant="button_outlined"
                  url={`/${promo_cta_url.cached_url}`}
                  type="internal"
                  with_icon
                >
                  {promo_cta_text}
                </LinkComponent>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.shape} data-promo={String(hasPromo)} />
      <div className="container">
        <div className={styles.awardsContainer} id="awards-box">
          <AwardWinners hasPromo={hasPromo} />
        </div>
      </div>
    </section>
  );
}
