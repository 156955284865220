// extracted by mini-css-extract-plugin
export var cardDetails = "styles-module--card-details--c9a13";
export var cardImageWrapper = "styles-module--card-image-wrapper--17ec6";
export var cardWrap = "styles-module--card-wrap--93dcc";
export var categories = "styles-module--categories--9dd87";
export var clipPath = "styles-module--clip-path--e827c";
export var date = "styles-module--date--e358a";
export var excerpt = "styles-module--excerpt--7cc21";
export var image = "styles-module--image--2f974";
export var labels = "styles-module--labels--535fa";
export var link = "styles-module--link--9b6ac";
export var readMore = "styles-module--read-more--b3ff7";
export var tag = "styles-module--tag--729bd";
export var title = "styles-module--title--05535";