interface Props {
  searchResults: [Array<HitNews>, number];
  getNameByValue: (value: CategoryType) => string;
  site: {
    siteMetadata: {
      ogImage: StoryblokGatsbyImageAsset;
    };
  };
}

export function formatNewsResult({
  searchResults,
  getNameByValue,
  site,
}: Props) {
  return searchResults?.[0].map((result) => {
    const baseData = {
      key: result.id,
      url: result?.slug,
      getNameByValue: getNameByValue,
    };
    switch (result.component) {
      case "template_scientificUpdate":
        return {
          ...baseData,
          publishedDate: result.su_date_of_publication,
          title: result.su_title,
          excerpt: result.su_excerpt,
          labels: result.su_labels,
          heroImage: result.su_hero_img || site.siteMetadata.ogImage,
          categories: result.su_categories,
        };
      case "template_event":
        return {
          ...baseData,
          publishedDate: result.event_date,
          title: result.event_title,
          excerpt: result.event_description,
          labels: result.event_labels,
          heroImage: result.event_thumbnail || site.siteMetadata.ogImage,
          categories: result.event_categories,
        };
      case "template_grant":
        return {
          ...baseData,
          publishedDate: result.gr_date,
          title: result.gr_title,
          excerpt: result.gr_description,
          labels: result.gr_labels,
          heroImage: result.gr_card_image || site.siteMetadata.ogImage,
          categories: result.gr_categories,
        };
      default:
        return;
    }
  });
}
