import Heading from "@/components/elements/Heading";
import leaf from "@/assets/svgs/resources/leaf.svg";
import bean from "@/assets/svgs/resources/bean.svg";

import { useLanguageContext } from "@/base/context/LanguageContext";
import * as styles from "./styles.module.scss";

export function ResourcesHero({ title, description }: ResourcesHeroType) {
  const { language_code } = useLanguageContext();

  return (
    <section className={styles.section}>
      <div className="container">
        {!["befr", "benl"].includes(language_code || "") && (
          <span className={styles.subheading}>Resources</span>
        )}

        <Heading level={1}>{title}</Heading>

        <p className={styles.description}>{description}</p>
      </div>
    </section>
  );
}
