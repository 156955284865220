import dayjs from "dayjs";
import { Link } from "gatsby";

import Heading from "@/components/elements/Heading";
import Image from "@/components/elements/Image";
import ImagePlaceholder from "@/components/elements/ImagePlaceholder";

import Tag from "@/components/elements/Tag";
import ClipPath from "@/components/elements/ClipPath";
import LinkComponent from "@/components/elements/LinkComponent";
import { useLanguageContext } from "@/base/context/LanguageContext";
import { prependUrlWithSlash } from "@/base/utils/prependUrlWithSlash";
import * as styles from "./styles.module.scss";

export function MainScientificUpdateCard({
  su_title,
  su_categories,
  su_date_of_publication,
  su_excerpt,
  su_hero_img,
  full_slug,
  su_labels,
  getNameByValue,
  ...rest
}: UpdatePageTemplate & {
  full_slug: string;
  getNameByValue: (value: CategoryType) => string;
}) {
  const { t } = useLanguageContext();

  return (
    <div className={styles.cardWrap}>
      <div className={styles.cardImageWrapper}>
        {/* <div className={styles.labels}>
          {Array.isArray(su_labels) &&
            su_labels.map((label) => (
              <Tag type={label} key={label} size="small" />
            ))}
        </div> */}

        {su_hero_img?.image ? (
          <Image
            data={su_hero_img}
            fluid
            options={{
              maxWidth: 600,
              maxHeight: 600,
            }}
            className={styles.image}
          />
        ) : rest?.image?.filename ? (
          <img src={rest?.image?.filename} alt="" className={styles.image} />
        ) : (
          <ImagePlaceholder />
        )}
      </div>

      <div className={styles.cardDetails}>
        <Link
          to={prependUrlWithSlash(full_slug)}
          className={styles.link}
          type="external"
        >
          {su_date_of_publication && (
            <span className={styles.date}>
              {dayjs(su_date_of_publication).format("DD MMMM YYYY")}
            </span>
          )}

          <Heading level={3} className={styles.title}>
            {rest?.title}
          </Heading>

          <p className={styles.excerpt}>{rest?.description}</p>
        </Link>

        <div className={styles.categories}>
          {Array.isArray(su_categories) &&
            su_categories.map((category) => (
              <Link
                to={`/updates/${category}`}
                key={category}
                className={styles.tag}
              >
                <Tag text={getNameByValue(category)} size="small" />
              </Link>
            ))}
        </div>

        <LinkComponent
          url={prependUrlWithSlash(full_slug)}
          type="external"
          with_icon
          className={styles.readMore}
        >
          {t`read-more`}
        </LinkComponent>
      </div>
    </div>
  );
}
