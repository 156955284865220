import { RefObject, useState } from "react";

const useClientPagination = (
  array: ResourceComponent[],
  elementRef: RefObject<HTMLDivElement>,
  itemsPerPage = 6
) => {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const showPagination = array.length > itemsPerPage;
  const paginatedArray = array.slice(itemOffset, endOffset);

  const pageCount = Math.ceil(array.length / itemsPerPage);

  const handlePageClick = (event: { selected: number }) => {
    const yOffset = -120;

    const element = elementRef?.current;

    if (typeof window !== "undefined") {
      const y =
        element &&
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({
        top: y || 0,
        behavior: "smooth",
      });
    }

    const newOffset = (event.selected * itemsPerPage) % array.length;

    setItemOffset(newOffset);
  };
  return { paginatedArray, handlePageClick, pageCount, showPagination };
};

export default useClientPagination;
