import { graphql, useStaticQuery } from "gatsby";
import { useMemo, useState } from "react";
import VerticalCard from "@/components/elements/VerticalCard";
import { useFetchAllTemplatePages } from "@/base/hooks/useFetchAllTemplatePages";
import HorizontalCard from "@/components/elements/HorizontalCard";
import { useIsMobile } from "@/base/hooks/useIsMobile";
import { BREAKPOINTS } from "@/base/constants/breakpoints";
import { getPaginatedResults } from "@/base/helpers/getPaginatedResults";
import Pagination from "@/components/elements/Pagination";
import { useCurrentPaginationPage } from "@/base/hooks/useCurrentPaginationPage";
import CtaCard from "@/components/elements/CtaCard";
import { useLocation } from "@reach/router";
import * as styles from "./styles.module.scss";

export function CardsGridWithLabel({
  label,
  cards,
  display_featured_card = false,
  cta_card,
}: CardsGridWithLabelType) {
  const isMobile = useIsMobile(BREAKPOINTS.md);
  const { currentPage: currentPageParam } = useCurrentPaginationPage();
  const [currentPage, setCurrentPage] = useState(currentPageParam || 0);
  const { parsedPages } = useFetchAllTemplatePages();
  const pagesToDisplay = parsedPages?.filter((page) =>
    cards?.includes(String(page?.uuid))
  );

  const slicedPagesToDisplay = pagesToDisplay.slice(
    display_featured_card && !isMobile ? 1 : 0
  );

  const { pages, totalPages } = useMemo(() => {
    return getPaginatedResults(slicedPagesToDisplay, { itemsPerPage: 20 });
  }, [slicedPagesToDisplay]);

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.label}>{label}</h2>

      <div className={styles.cardsContainer}>
        <div className={styles.cardsGrid}>
          {display_featured_card && !isMobile && currentPage === 0 && (
            <HorizontalCard
              key={pagesToDisplay?.[0]?.title}
              className={`${styles.card} ${styles.horizontalCard}`}
              title={pagesToDisplay?.[0]?.title}
              excerpt={pagesToDisplay?.[0]?.excerpt}
              heroImage={{ image: pagesToDisplay?.[0]?.image?.filename }}
              url={pagesToDisplay?.[0]?.cta?.[0]?.url}
              ctaLabel={pagesToDisplay?.[0]?.cta?.[0]?.title}
              variant="page-preview"
            />
          )}

          {pages?.[currentPage]?.map((page) => (
            <VerticalCard
              key={page?.title}
              className={styles.card}
              title={page?.title}
              excerpt={page?.excerpt}
              heroImage={{ image: page?.image?.filename }}
              url={page?.cta?.[0]?.url}
              ctaLabel={page?.cta?.[0]?.title}
              variant="page-preview"
            />
          ))}
        </div>

        {totalPages > 1 && (
          <Pagination
            totalPages={totalPages}
            currentPage={Number(currentPage)}
            setPage={setCurrentPage}
            className={styles.pagination}
          />
        )}

        {cta_card?.[0] && (
          <CtaCard
            cta={cta_card?.[0].cta}
            title={cta_card?.[0].title}
            image={cta_card?.[0].image}
            content_orientation={cta_card?.[0].content_orientation}
            className={`${styles.ctaCard} ${styles.ctaCardDesktop}`}
          />
        )}
      </div>

      {cta_card?.[0] && isMobile && (
        <CtaCard
          cta={cta_card?.[0].cta}
          title={cta_card?.[0].title}
          image={cta_card?.[0].image}
          content_orientation={cta_card?.[0].content_orientation}
          className={`${styles.ctaCard} ${styles.ctaCardMobile}`}
        />
      )}
    </div>
  );
}
