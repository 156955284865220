import LinkComponent from "../LinkComponent";
import * as styles from "./styles.module.scss";

interface Props {
  children: React.ReactNode;
  url: string;
  className?: string;
}

export function SeeAllButton({ children, url, className }: Props) {
  return (
    <div className={`${styles.seeAllButton} ${className}`}>
      <hr />

      <LinkComponent
        url={url}
        type={"internal"}
        className={styles.button}
        with_icon={true}
      >
        {children}
      </LinkComponent>

      <hr />
    </div>
  );
}
