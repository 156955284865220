import VerticalCard from "@/components/elements/VerticalCard";

import HorizontalCard from "@/components/elements/HorizontalCard";
import { useIsMobile } from "@/base/hooks/useIsMobile";
import { BREAKPOINTS } from "@/base/constants/breakpoints";
import SeeAllButton from "@/components/elements/SeeAllButton";
import { useLanguageContext } from "@/base/context/LanguageContext";
import * as styles from "./styles.module.scss";

type ScientificCardData = {
  title: string;
  excerpt?: string;
  heroImage?: StoryblokGatsbyImageAsset;
  labels?: LabelType[];
  categories: CategoryType[];
  publishedDate: string;
  url: string;
  getNameByValue: (value: CategoryType) => string;
};

interface ResourceListProps {
  title?: string;
  list?: Array<ResourceComponent>;
  scientificList?: Array<ScientificCardData>;
  isFeaturedCardDisplayed?: boolean;
  cta?: {
    label: string;
    url: string;
  };
  isPaginationDisplayed?: boolean;
  categoryId?: string;
}

const BADGE_LABEL_TEXT: Record<string, string> = {
  mp4: "MP4",
  pdf: "PDF",
};

const URL_FIELD: Record<string, string> = {
  mp4: "video",
  pdf: "pdf",
};

export const ResourceList = ({
  title,
  list,
  isFeaturedCardDisplayed = false,
  cta,
  isPaginationDisplayed = false,
  categoryId,
}: ResourceListProps) => {
  const isMobile = useIsMobile(BREAKPOINTS.lg);

  const { t } = useLanguageContext();

  const getCtaLabelText = (type) => t([`resources.${type}`]);

  let slicedList =
    !isFeaturedCardDisplayed || isMobile ? list?.slice(0) : list?.slice(1);

  if (!isPaginationDisplayed) {
    slicedList = slicedList?.slice(0, 2);
  }

  const firstItem = list?.[0];

  return (
    <div className={styles.listContainer}>
      <div className={styles.titleBox}>
        {title || t([`resources.${categoryId}`])}
      </div>

      <div className="container">
        <div className={styles.cardsWrapper}>
          {isFeaturedCardDisplayed && !isMobile && (
            <div className={styles.featuredCard}>
              <HorizontalCard
                badge={BADGE_LABEL_TEXT[firstItem?.type]}
                key={`${title}_${firstItem?.title}`}
                title={firstItem?.title}
                excerpt={firstItem?.description}
                url={firstItem?.[URL_FIELD?.[firstItem?.type]]?.filename}
                heroImage={firstItem?.image}
                ctaLabel={getCtaLabelText(firstItem?.type)}
              />
            </div>
          )}

          <div className={styles.list}>
            {Array.isArray(slicedList) &&
              slicedList.map((item, i) => (
                <VerticalCard
                  badge={BADGE_LABEL_TEXT[item?.type]}
                  key={`${title}_${item.title}${i}`}
                  title={item.title}
                  excerpt={item.description}
                  url={item?.[URL_FIELD?.[item?.type]]?.filename}
                  heroImage={item.image}
                  ctaLabel={getCtaLabelText(item?.type)}
                />
              ))}
          </div>

          {!isPaginationDisplayed && cta?.url && cta?.label && (
            <SeeAllButton url={cta.url} className={styles.seeAllButton}>
              {cta.label}
            </SeeAllButton>
          )}
        </div>
      </div>
    </div>
  );
};
