export const winnersSortedByDate = (winners: Array<HitSUTemplate>) => {
  const NUMBER_OF_LATEST_WINNERS = 2;

  const sortByTimestamp = (a: HitSUTemplate, b: HitSUTemplate) => {
    const timestampA = new Date(
      a.su_date_of_publication.split(" ")[0]
    ).getTime();
    const timestampB = new Date(
      b.su_date_of_publication.split(" ")[0]
    ).getTime();

    return timestampA > timestampB ? -1 : 1;
  };

  const winnersSortedByDate = winners.sort(sortByTimestamp);

  const latestWinners = winnersSortedByDate.slice(0, NUMBER_OF_LATEST_WINNERS);
  const previousWinners = winnersSortedByDate.slice(NUMBER_OF_LATEST_WINNERS);

  return { latestWinners, previousWinners };
};
