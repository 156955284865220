import Button from "@/components/elements/Button";
import Heading from "@/components/elements/Heading";
import LinkComponent from "@/components/elements/LinkComponent";
import ContactUsForm from "@/components/elements/ContactUsForm";
import RemarkRichTextComponents from "@/components/elements/RichText";

import Icon from "@/components/elements/Icon";

import * as styles from "./styles.module.scss";

export function ContactUs({
  actions,
  heading,
  subheading,
  list,
}: ContactUsSectionType) {
  return (
    <section className={styles.newsletterSection}>
      <div className="container">
        <div className={styles.descriptionColumn}>
          <Heading level={2} className={styles.title}>
            {heading || "Subscribe to our newsletter"}
          </Heading>

          {list && list.length > 0 && (
            <>
              <h5 className={styles.listTitle}>{list[0].title}</h5>
              <ul>
                {list[0].list.map((item, index) => (
                  <li key={`item-${index}`} className={styles.listItem}>
                    <Icon name="circleCheckmark" className={styles.icon} />
                    <RemarkRichTextComponents content={item.text} />
                  </li>
                ))}
              </ul>
            </>
          )}

          {subheading ? (
            <p className={styles.subheading}>{subheading}</p>
          ) : null}

          {Array.isArray(actions) &&
            actions.map((item) => (
              <Button variant="outlined" key={item._uid}>
                <LinkComponent
                  url={item.url}
                  type={item.type}
                  with_icon={item.with_icon}
                  className={styles.cta}
                >
                  {item.title}
                </LinkComponent>
              </Button>
            ))}
        </div>

        <ContactUsForm />
      </div>
    </section>
  );
}
