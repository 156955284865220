import { useEffect } from "react";

interface Props {
  paperFormId: string;
}

export const PaperFormEmbed = ({ paperFormId }: Props) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://paperform.co/__embed.min.js";
    document.body.appendChild(script);
  }, []);

  return (
    <div
      data-paperform-id={paperFormId}
      data-spinner={1}
      className="paperForm"
    />
  );
};
